console.log('ele2023');

$(function() {
    // Store the value of the furthest step we have validated to ensure that we keep validating it if the user leaves the tab
    let regValidationTabHistory = 1;

    const swiper = new Swiper(".swiper", {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 10,
        effect: 'coverflow',
        spaceBetween: 30,
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 1,
            slideShadows: false,
        },        
        pagination: {
          el: ".swiper-pagination",
          clickable: false,
        },
        breakpoints: {
            900: {
                slidesPerView: 'auto'
            },
            760: {
                slidesPerView: 2
            }

        } ,
        autoplay: {
            delay: 7000, // time duration between slides (in ms)
            disableOnInteraction: false, // if set to true, autoplay will stop after user interaction
        },        
      });

    $('.regNextButton, #RegisterFormSubmit').on('click', function() {
        // Get the data attributes for the button
        let currentStep = $(this).data("section"); 
        regValidationTabHistory = currentStep;
        let target = $('.ele-registerForm .nav-pills .nav-item .nav-link');

        // Validate all steps the customer has tried to submit in this session
        let isValid = validateSection(regValidationTabHistory);

        if (isValid) {    
            // Mark all valid steps as completed            
            target.eq(currentStep - 1).removeClass('invalid').addClass('complete');

            if($('.nav-pills .nav-item').length === currentStep){
                // Do nothing and let normal form submission take place
                return true
            } else { 
                // Move to the next relevant tab
                $(`#pills-step${currentStep + 1}-tab`).tab('show');
            }
        } else {
            // Extract the parent tab from the data of each error element so we can flag it with an error class
            let errorTabs = [];
            let validTabs = [];
            let errors = $('.error-element-link');
            errors.each(function(index, item) {
                let elem = $(item).data('element');
                let target = $("[name='" + elem + "']");
                // seperate successfully validated sections from those containing errors without setting classes on non started sections
                if(!errorTabs.includes(target.data('tab'))){
                    errorTabs.push(target.data('tab'));
                }
            });
            // seperate successfully validated sections from those containing errors without setting classes on non started sections
            for(s = 0; s < regValidationTabHistory; s++){
                if(!errorTabs.includes(s + 1)){
                    validTabs.push(s + 1);
                };
            }          
            // Add appropriate classes to the now identified valid/error tabs
            errorTabs.forEach(function(currentValue) {
                $(`#pills-step${currentValue}-tab`).removeClass('complete').addClass('invalid');
            });
            validTabs.forEach(function(currentValue) {
                $(`#pills-step${currentValue}-tab`).removeClass('invalid').addClass('complete');
            });            
        }  
    });  

    function validateSection(section) {
        // First, tell jQuery Validate to ignore all inputs
        $("#RegisterForm").validate({
            ignore: "*"
        });

        // Gather the classes of all the sections we ned to validate in this round
        let sectionList = [];
        for (let s = 1; s <= section; s++ ){
            sectionList.push(`.pills-step${s}-input`);     
        }
        let ignoreString  = sectionList.map(function(section) {
            return section;
        }).join(", ");
    

        // Then, update the ignore option to only validate our desired sections
        $("#RegisterForm").validate().settings.ignore = `:not(${ignoreString})`;    

        // Check if the form is valid now
        return $("#RegisterForm").valid();
    }
    
    function resetRegistrationForm(){
        $(`#pills-step1-tab`).tab('show');
    }
    
    // validate the registration form sections when the page is loaded
    if ($('#RegisterForm').length > 0) {
        resetRegistrationForm();
    }

    $('#all-tab').on('click', function(){
        $('.tab-pane').addClass('tab-pane-force-reveal');  
    });

    $('.indiv-tab').on('click', function(){
        $('.tab-pane').removeClass('tab-pane-force-reveal');  
    });

    if ($('.ele-subsiteProducts').length > 0){
        // Get the hash fragment from the URL
        var hashFragment = window.location.hash;

        // Check if a hash value exists
        if (hashFragment) {
            // Remove the '#' symbol from the hash fragment
            var argumentValue = hashFragment.slice(1);

            // Show the tab
            if($(`#tab-control-${argumentValue}`).length > 0){
                $(`#tab-control-${argumentValue}`).tab('show');
                let navElement = $("#pills-tab").offset().top;
                let scrollToPosition = navElement - 200;

                $("html, body").animate({
                    scrollTop: scrollToPosition
                  }, 0);
            } else {
                // If no fragment, open the all tab
                $('#all-tab').trigger('click');
            }
        } else {
            // If no fragment, open the all tab
            $('#all-tab').trigger('click');
        }
    }
    
    $("#RegisterFormCustomerPassword").on('input', function() {
        $("#RegisterForm").validate().settings.ignore = `:not(#RegisterFormCustomerPassword)`;   
        $(this).valid(); 
    });

    $('.clear-icon i').on('click', function(){
        $('#search').val(''); // Clear the input field
        $('#search').focus(); // Optional: focus the input after clearing
    });


    var $inputField = $('.registerWithoutCodeInput')
    var $submitButton = $('#registerWithoutCodeSubmit')

    $submitButton.prop('disabled', false);

    $inputField.on('input', function() {
        if($inputField.val().trim() === '') {
            $submitButton.prop('disabled', false);
        } else {
            $submitButton.prop('disabled', true);
        }
    });

    if ($('body').hasClass('e_body_mySummary')) {
        let activityTemplate = _.template('<tr class="e_document_activity_item e_document_history_type_<%-MESSAGETYPE%>">' +
        '<td class="e_document_activity_item_date"><%-DATE%></td>\n' +
        '<td class="e_document_activity_item_content"><span class="audit-log-icon"></span><div><strong><%-DOCUMENTNAME%></strong><br/><%-ACTION%></div></td>' +
        '</tr>');
    
        $('#activityContentFetch').click(function() {
    
            //loading gif
            $('#activityContent #activityContentLoad').show();
    
            ele.handleAJAX(
                "POST",
                ele.uri.buildUrl("getDocumentAuditFeed", {filter: "1000,1001,1002,1012,1006,1007,1008,1009,1011,1030,1031,1032,1033,1034,1035,1036,1100,2001,2005,2006,2010,2011,2012,2013,2014,5001,5002"}),
                null,
                true
            ).then(response => {
                if (response.length > 0) {
                    for (let i = 0; i < response.length; i++) {
                        $('#activityContent #activityContentTable tbody').append(activityTemplate(response[i]));
                    }
                    $('#activityContent #activityContentFetch').hide();
                    $('#activityContent #activityContentLoad').hide();
                    $('#activityContent #activityContentTable').show();
                } else {
                    $('#activityContent #activityContentFetch').hide();
                    $('#activityContent #activityContentLoad').hide();
                    $('#activityContent #activityContentNoneFound').show();
                }
            });
        });
    }



})

$(document).on("click", ".faq-navigation .box", function() {
    var faqContent = $(this).closest(".box").find(".faq-content");
    faqContent.toggleClass("sHidden ");

    var icon = $(this).find("i");
    icon.toggleClass("fa-arrow-square-down fa-arrow-square-up");
});


 // Function to find and display the breadcrumb trail for a selected link
function findBreadcrumbForSelectedLink() {
    // Find the selected items with class "breadcrumbSelected"
    const selectedItem = $("a.open");

    if (selectedItem.length) {
      // Initialize an array to store the breadcrumb trail
      const breadcrumbTrail = [];

      selectedItem.each(function() {
        // Get the href and text of each selected item
        const href = $(this).attr("href");
        const text = $(this).text().trim();

        // Create a breadcrumb link with the correct href if it's not undefined
        const breadcrumbLink = href ? `<a href="${href}">${text}</a>` : text;

        // Add it to the breadcrumb trail
        breadcrumbTrail.push(breadcrumbLink);
      });

      // Join the breadcrumb items with <i> tags and display the trail
      const breadcrumbString = breadcrumbTrail.join('<i class="p-3 fas fa-caret-right"></i>');

      // Replace the contents of the "current" span with the breadcrumb trail
      $("span.current").html(breadcrumbString);
    }
  }

  // Call the function when the page loads
  $(document).ready(function() {
    findBreadcrumbForSelectedLink();
  });

  $(document).ready(function() {
    if ($('.home-AALQ').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-AttorneyConsultation').length > 0  && $('.home-DocumentReview').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AttorneyConsultation').addClass('col-lg-6');$('.home-DocumentReview').addClass('col-lg-6');$('.home-AttorneyConsultation').removeClass('col-lg-4');$('.home-DocumentReview').removeClass('col-lg-4');$('.home-AttorneyConsultation-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-AttorneyConsultation').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-AALQ').length > 0  && $('.home-DocumentReview').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AALQ').addClass('col-lg-6');$('.home-DocumentReview').addClass('col-lg-6');$('.home-AALQ').removeClass('col-lg-4');$('.home-DocumentReview').removeClass('col-lg-4');$('.home-AALQ-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-AALQ').length > 0  && $('.home-AttorneyConsultation').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AALQ').addClass('col-lg-6');$('.home-AttorneyConsultation').addClass('col-lg-6');$('.home-AALQ').removeClass('col-lg-4');$('.home-AttorneyConsultation').removeClass('col-lg-4');$('.home-AALQ-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length > 0 && $('.home-AALQ').length > 0  && $('.home-AttorneyConsultation').length === 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-MakeAClaim').addClass('col-lg-6');$('.home-AALQ').addClass('col-lg-6');$('.home-MakeAClaim').removeClass('col-lg-4');$('.home-AALQ').removeClass('col-lg-4');$('.home-MakeAClaim-full-width').hide();$('.home-AALQ-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length > 0 && $('.home-MakeAClaim').length > 0 && $('.home-AALQ').length === 0  && $('.home-AttorneyConsultation').length === 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-MakeAClaim').addClass('col-lg-6');$('.home-DocumentReview').addClass('col-lg-6');$('.home-MakeAClaim').removeClass('col-lg-4');$('.home-DocumentReview').removeClass('col-lg-4');$('.home-MakeAClaim-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length > 0 && $('.home-AALQ').length === 0  && $('.home-AttorneyConsultation').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-MakeAClaim').addClass('col-lg-6');$('.home-AttorneyConsultation').addClass('col-lg-6');$('.home-MakeAClaim').removeClass('col-lg-4');$('.home-AttorneyConsultation').removeClass('col-lg-4');$('.home-MakeAClaim-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-AALQ').length === 0 && $('.home-AttorneyConsultation').length > 0 && $('.home-DocumentReview').length > 0  && $('.home-MakeAClaim').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AttorneyConsultation-full-width').hide();$('.home-MakeAClaim-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-AALQ').length > 0 && $('.home-AttorneyConsultation').length === 0 && $('.home-DocumentReview').length > 0  && $('.home-MakeAClaim').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AALQ-full-width').hide();$('.home-MakeAClaim-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-AALQ').length > 0 && $('.home-AttorneyConsultation').length > 0 && $('.home-DocumentReview').length === 0  && $('.home-MakeAClaim').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AALQ-full-width').hide();$('.home-MakeAClaim-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-AttorneyConsultation').length === 0 && $('.home-AALQ').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AALQ').hide();$('.home-AALQ-full-width').show();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-AttorneyConsultation').length > 0 && $('.home-AALQ').length === 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AttorneyConsultation').hide();$('.home-AttorneyConsultation-full-width').show();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length > 0 && $('.home-MakeAClaim').length === 0 && $('.home-AttorneyConsultation').length === 0 && $('.home-AALQ').length === 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-DocumentReview').hide();$('.home-DocumentReview-full-width').show();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length > 0 && $('.home-MakeAClaim').length === 0 && $('.home-AttorneyConsultation').length > 0 && $('.home-AALQ').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-AALQ-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length > 0 && $('.home-AttorneyConsultation').length === 0 && $('.home-AALQ').length === 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-MakeAClaim').hide();$('.home-MakeAClaim-full-width').show();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length > 0 && $('.home-MakeAClaim').length > 0 && $('.home-AttorneyConsultation').length > 0 && $('.home-AALQ').length > 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-MakeAClaim').hide();$('.home-AALQ-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }

    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-AttorneyConsultation').length === 0 && $('.home-AALQ').length === 0 && $('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 ) {
        $('.home-MakeAClaim').hide();$('.home-AALQ-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }

    else if ($('.home-USHealthCheckup').length === 0 && $('.home-USBusinessLawGuide').length === 0 && $('.home-AttorneyConsultation').length > 0 && $('.home-AALQ').length > 0 && $('.home-MakeAClaim').length === 0 && $('.home-DocumentReview').length === 0) {
        $('.home-MakeAClaim').hide();$('.home-AALQ-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument-full-width').hide();
    }
    else if ($('.home-DocumentReview').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-AttorneyConsultation').length === 0 && $('.home-AALQ').length === 0 && $('.home-USHealthCheckup').length === 0 && $('.home-USBusinessLawGuide').length === 0 ) {
        $('.home-MakeAClaim').hide();$('.home-AALQ-full-width').hide();$('.home-DocumentReview-full-width').hide();$('.home-AttorneyConsultation-full-width').hide();$('.home-CreateALegalDocument').hide();
    }
    else if ($('.home-USHealthCheckup').length > 0 && $('.home-USBusinessLawGuide').length > 0 && $('.home-AttorneyConsultation').length === 0 && $('.home-AALQ').length === 0 && $('.home-MakeAClaim').length === 0 && $('.home-DocumentReview').length === 0 && $('.home-CreateALegalDocument').lenght > 0 ) {
        $('.home-CreateALegalDocument-full-width').hide();
    }
});

$(document).ready(function() {
    if ($('.business-guide').length > 0 && $('.personal-guide').length > 0) {
        $('.business-guide-title').show();
        $('.personal-guide-title').show();
    } else if ($('.business-guide').length > 0 || $('.personal-guide').length > 0) {
        $('.business-guide-title').hide();
        $('.personal-guide-title').hide();
    }
});

